.group-select {
  .table-component__row {
    td:first-child {
      text-align: left;
      margin-left: 10px;
    }
    svg {
      display: block;
      height: 25px;
      margin-left: auto;
      margin-right: 10px;
    }
  }
}
