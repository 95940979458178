#user-edit-from {
  max-height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    width: 50%;
  }
  .checkbox-component {
    margin: 0;
  }
}
.user-edit-form-wrapper {
  max-height: 100%;
}

@media (max-width: 693px) {
  #user-edit-from {
    display: flex;
    flex-direction: column;
    .input-component,
    .dropdown-component,
    .checkbox-component,
    .group-select {
      width: 100%;
    }

    .user-edit-input-wrapper {
      width: 100% !important;
    }
  }
}
