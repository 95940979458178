html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: calc(100%);
}

.horizontal-buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .button-component {
    width: 300px;
  }
}
.color-white {
  color: white;
}

.central-alignment {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard-page {
  &--wrapper {
    position: relative;
    margin: 0 auto;
    padding: 0 10px;
    max-width: 1050px;
  }

  &--tile {
    width: 300px;
    background-color: #0596ff;
    height: 100px;
    border-radius: 15px;
    padding: 10px;
    cursor: pointer;
    color: #2a2d31;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: larger;
  }
}

.clickable {
  cursor: pointer;
}

.checkbox-button-combo-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  white-space: nowrap;

  &--title {
    flex-grow: 1;
  }

  .checkbox-component {
    input {
      background: white;
    }
  }
}

.growing-button-content {
  display: flex;
  align-items: center;

  p {
    display: block;
    -webkit-transition: 0, 15s linear;
    -moz-transition: 0.15s linear;
    transition: 0.15s linear;
    max-width: 0;
    overflow: hidden;
    white-space: nowrap;
  }
  &:hover {
    p {
      margin-right: 4px;
      max-width: 150px;
    }
  }
}

.desktop-only {
  @media screen and (max-width: 800px) {
    display: none;
  }
}
