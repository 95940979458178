.company-overview {
  &__license-wrapper {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    & > svg {
      height: 24px;
      width: 24px;
    }
  }
}
