.task-overview {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    align-items: center;
    .input-component {
      max-width: 300px;
    }
    .button-component {
      width: fit-content;
    }

    &__close {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  &__list-wrapper {
    overflow-y: auto;
    flex: 1;
  }
  &__config-box {
    margin: 10px 20px;
    padding: 10px 0;
  }
  &__task {
    padding-left: 20px;
  }
  &__archive-button {
    &__wrapper {
      width: fit-content;
      margin-left: auto;
    }
    &__content {
      &--white {
        color: white;
      }

      &--black {
        color: black;
      }
    }
  }
  &__no-task-found {
    padding: 20px;
  }
}
