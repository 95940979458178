.shopfloor-column-task {
  background-color: white;
  border-radius: 20px;
  -webkit-box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.26);
  box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.26);
  box-sizing: border-box;
  padding: 5%;
  margin: 5% 3%;
  position: relative;
  display: flex;
  flex-direction: column;

  &--minified {
    margin: 10px 3%;
    padding: 10px;
  }
  &--icon-wrapper {
    position: absolute;
    right: 4px;
    top: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &--center {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &--status {
    height: 15px;
    width: 15px;
    border-radius: 7.5px;
    margin: 5px;
  }
  &--title {
    margin: 0 5% 5% 5%;
    text-align: left;
    &--minified {
      margin: 0;
    }
  }
  &--content {
    margin: 0 5%;
    text-align: left;
    cursor: pointer;
    &--hide {
      display: none;
    }
  }
  .popup-component-clickaway {
    .dropdown-component-dropdown__menu {
      &-list {
        margin-top: -15px;
        @media screen and (max-width: 600px) {
          max-height: 120px;
        }
      }
    }
  }
}

.pdca-quartered-circle {
  width: 50px;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
  margin: 0 auto;
  margin-top: 20px;

  circle {
    fill: transparent;
    stroke: black;
    stroke-width: 2;
  }

  path {
    fill: black;
  }
}

.task-edit {
  &__pdca-wrapper {
    display: flex;
    align-items: center;
  }
  &__side-information {
    padding: 10px;
    flex: 1;
    background-color: rgb(150, 154, 250);
    border-radius: 20px;
    height: max-content;

    .input-component--label {
      font-weight: bold;
    }
  }
  &__basis-information {
    flex: 4;

    &__wrapper {
      flex: 1;
      margin-top: 15px;
      display: flex;
      overflow-y: auto;

      @media only screen and (max-width: 600px) {
        flex-direction: column;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
  }
  &__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__button-content {
      display: flex;
      align-items: center;

      p {
        display: block;
        -webkit-transition: 0, 15s linear;
        -moz-transition: 0.15s linear;
        transition: 0.15s linear;
        max-width: 0;
        overflow: hidden;
        white-space: nowrap;
      }

      &:hover {
        p {
          margin-right: 4px;
          max-width: 150px;
        }
      }

      &__save {
        @media screen and (min-width: 601px) {
          display: none;
        }
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .button-component {
      margin-left: auto;
      width: max-content;
    }
    &__button-wrapper {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
  }

  &__media {
    flex: 1;
    overflow-y: auto;
    &__wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      & > p {
        font-weight: bold;
        margin: auto;
        padding: 5px;
      }
    }
    &__camera-placeholder {
      flex: 1;
      width: 100%;
    }
  }
  &__document {
    flex: 1;
    overflow-y: auto;
    &__pdf-pop-up {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      display: flex;
      flex-direction: column;
      background-color: rgb(150, 154, 250);
      transition: transform 0.3s;
      div {
        display: flex;
        justify-content: flex-end;
        padding: 10px;
        svg {
          height: 24px;
          width: 24px;
          margin-left: auto;
        }
      }

      object {
        flex: 1;
      }

      &--disabled {
        transform: translateY(100%);
      }
    }
    &__wrapper {
      position: relative;
      min-height: 20vh;
      .button-component button {
        color: white;
      }
      &__download--disabled {
        opacity: 30%;
      }
      .table-component__table-wrapper {
        padding: 2px;
        height: max-content;
        .table-component__row {
          height: 60px;
        }
      }
    }
    &__no-file {
      padding: 10px;
    }
  }
}
