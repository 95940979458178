.board-edit-form {
  &--content {
    .input-with-checkbox {
      display: flex;
      .checkbox-component {
        align-self: flex-end;
        height: 40px;
        margin: 10px 0;
        margin-right: 10px;
      }
    }

    hr {
      margin-top: 10px;
    }

    p {
      font-weight: bold;
    }
  }

  &--delete {
    gap: 10px;

    .popup-component-wrapper--header-title {
      padding-right: 15px;
    }
  }

  &--header {
    display: flex;
    align-items: center;

    .button-component {
      margin-left: auto;
      width: max-content;
    }
    &--button-content {
      display: flex;
      align-items: center;
      p {
        display: block;
        -webkit-transition: 0, 15s linear;
        -moz-transition: 0.15s linear;
        transition: 0.15s linear;
        max-width: 0;
        overflow: hidden;
        white-space: nowrap;
      }
      &:hover {
        p {
          margin-right: 4px;
          max-width: 150px;
        }
      }
    }
  }
}
