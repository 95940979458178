.company-review {
  &--actions {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    & > svg {
      padding: 4px;
      border-radius: 100%;
      background-color: #7e81d3;
      height: 32px;
      width: 32px;
    }
  }
}
