@import "./Group.scss";

.shopfloor-dashboard-wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: 1050px;

  &--adding {
    padding: 0px 20px;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &-view {
      .view-svg {
        margin-right: 20px;
        height: 40px;
        width: 40px;
      }
      .list-icon {
        height: 32px;
      }
      .active {
        fill: #407bfd;
      }
    }

    svg {
      height: 50px;
      width: 50px;
      cursor: pointer;
    }
  }

  &--grid {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }
  @include groupList;
}

.shopfloor-board {
  &-detail--wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 20px;
    height: 24px;
    gap: 15px;

    .button-component {
      padding: 0;
      width: unset;

      > button {
        padding: 0;
        display: flex;
        align-items: center;
        background-color: unset !important;
        border: unset !important;
        &:not(.kiosk) {
          pointer-events: none;
        }

        p {
          display: block;
          transition: 0.15s ease-in-out;
          max-width: 0;
          overflow: hidden;
          white-space: nowrap;
        }

        &:hover {
          p {
            margin-right: 4px;
            max-width: 150px;
          }
          svg.nested {
            display: unset;
            max-width: 50px;
            margin-right: 8px;
          }
        }

        svg {
          pointer-events: auto;
          cursor: pointer;
          height: 24px;

          &.nested {
            transition: 0.15s ease-in-out;
            max-width: 0;
            overflow: hidden;
          }
        }
      }
    }
  }

  &--wrapper {
    position: absolute;
    top: 34px;
    left: 0;
    right: 0;
    bottom: 0;

    overflow-y: scroll;

    &--navigation {
      position: fixed;
      box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(20px);
      position: absolute;
      z-index: 1;
      transition: all 75ms ease-in-out;
      cursor: pointer;
      background-color: #000000cc;

      svg {
        height: 75px;
        width: 75px;
        fill: white;
      }

      &.left {
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        border-radius: 0 100% 100% 0;

        &:hover {
          transform: translate(0, -50%) scale(1.5);
        }
      }
      &.right {
        top: 50%;
        left: 100%;
        transform: translate(-100%, -50%);
        border-radius: 100% 0 0 100%;

        &:hover {
          transform: translate(-100%, -50%) scale(1.5);
        }
      }
    }
  }
  &__edit-header {
    display: flex;
    align-items: center;

    h2 {
      flex: 1;
    }
    &__button-content {
      display: flex;
      align-items: center;
      p {
        display: block;
        -webkit-transition: 0, 15s linear;
        -moz-transition: 0.15s linear;
        transition: 0.15s linear;
        max-width: 0;
        overflow: hidden;
        white-space: nowrap;
      }
      &:hover {
        p {
          margin-right: 4px;
          max-width: 150px;
        }
      }
    }
    .button-component {
      margin-left: auto;
      width: max-content;
    }
  }

  &--column {
    &-slider {
      .content {
        display: flex;
        flex-direction: row;
        overflow-x: hidden;
        width: 100vw;

        &:fullscreen {
          padding-top: 10px;
          background-color: white;
          .shopfloor-column {
            height: calc(100vh - 10px);
          }
        }

        > .page {
          display: flex;
          min-width: 100vw;
          width: 100vw;
          flex-direction: row;
          flex-grow: 1;
          gap: 10px;
          justify-content: space-around;

          > div {
            flex-grow: 1;
          }
        }
      }
    }
  }

  &--column &--task-title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
  }
}

.sfm-board-overview {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  &--entry {
    width: 250px;
    margin: 25px;
    height: 150px;
    background-color: #0596ff;
    color: #2a2d31;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    border-radius: 15px;
    position: relative;

    .close-icon {
      cursor: pointer;
      font-weight: bold;
      position: absolute;
      top: 5%;
      right: 5%;
      z-index: 10;
    }
  }
}

.new-shopfloor {
  &--wrapper {
    height: 100%;
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &--vertical-wrapper {
      height: 350px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      &__column {
        overflow-y: scroll;
        text-align: left;
        width: 45%;

        &-title {
          margin-top: 10px;
        }
      }
    }
  }

  &--column-wrapper {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    &-index {
      width: 30px;
      font-weight: bold;
    }

    &-content {
      padding: 10px 20px;
      border-radius: 25px;
      background: #969afa;
      flex-grow: 1;
      display: flex;

      &__name {
        flex-grow: 1;
      }

      &__remove {
        user-select: none;
        cursor: pointer;
        text-align: right;
        font-weight: bold;
        width: 40px;
      }
    }
  }
}

.shopfloor-task-creation {
  display: flex;
  justify-content: center;

  &--wrapper {
    width: 100%;
    max-width: 800px;
    padding: 10px;
  }

  &--footer {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}
