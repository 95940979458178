.protocol-filled {
  &__wrapper {
    padding-left: 20px;
    max-width: 800px;
    margin: auto;
  }
  &__header {
    display: flex;
    svg {
      width: 30px;
      margin: 0 24px 0 auto;
      cursor: pointer;
    }
  }
}
