@mixin groupList {
  &--list {
    display: flex;
    overflow-x: scroll;
    flex-direction: column;
    align-items: center;
    .retractable-component {
      box-sizing: border-box;
      font-family: "Avenir Next", Helvetica, Arial, sans-serif;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: space-between;
      position: relative;
      padding: 0px;
      &--header {
        padding: 20px;
        height: 80px;
        display: flex;
        align-items: center;
        font-weight: bold;
        &--arrow {
          right: 30px;
        }
      }
      &--content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
}
