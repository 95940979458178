.outer-wrapper {
  width: fit-content;
  max-width: 800px;
  display: flex;
  padding: 1rem;
  border-radius: 15px;
  margin: 0 auto;
  flex-direction: column;
}
.weather-component-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  flex-wrap: wrap;
  &--icon {
    height: 80px;
    width: 80px;
    align-self: center;
  }
  &--overview {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    border-radius: 5%;
  }
  &--temp {
    font-size: 2rem;
    font-weight: bold;
  }

  &--conditions {
    font-size: 1.5rem;
    text-align: center;
  }
}
.icon-small {
  width: 35px;
  height: 35px;
  @media screen and (max-width: 800px) {
    width: 20px;
    height: 20px;
  }
}
.wind-and-cloud {
  display: grid;
  grid-template-columns: min-content min-content;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: 800px) {
    grid-template-columns: min-content;
  }
}

.sun-shine-overview {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-grow: 1;
  flex-basis: 30%;
}
.rain-overview,
.wind-overview {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 0.5rem;
  justify-content: flex-end;
}
.entry {
  display: inline-block;
  margin-left: 0.5rem;
  padding: 0.5rem;
}
.weather-forecast {
  padding: 1rem;
  &--item-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0.5rem;
  }

  &--info-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    height: 2rem;
    font-size: 1rem;
    align-items: center;
    flex-grow: 1;
  }
  &--entry {
    list-style: none;
    border-bottom: 2px solid black;
    padding: 0.5rem;
    &--text {
      flex-grow: 1;
      font-size: 1.4rem;
      justify-content: flex-end;
      display: flex;
    }
    &--icon {
      display: flex;
      svg {
        width: 50px;
      }
      flex-grow: 1;
      justify-content: flex-end;
    }
  }

  .sunbar-container {
    position: relative;
    width: 100%;
    height: 60%;
  }

  .sunbar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 9;
    background-color: #fdd875;
    border-radius: 3px;
  }
  .sunbar-wrapper {
    box-sizing: border-box;
    padding: 0 1rem;
    width: 100%;
  }
  .sunbar-outline {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: lighten(#3f7bfc, 10%);
    border-radius: 3px;
  }
}
