.header-button-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  @media screen and (min-width: 801px) {
    margin-top: -30px;
  }

  &-title {
    display: flex;
    white-space: nowrap;
    flex-direction: column;
    width: 20%;
    min-width: min-content;
    justify-content: center;
    align-items: center;
    color: white;

    &-text {
      padding: 10px 20px;
      background: black;

      @media only screen and (max-width: 800px) {
        border-radius: 40px;
      }

      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
    }

    &-icon {
      cursor: pointer;
      background: black;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -20px;

      svg {
        color: white;
        height: 24px;
      }
    }
  }
}
