.shopfloor-column {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;

  height: calc(100vh - 132px);

  @media screen and (max-width: 800px) {
    height: calc(100vh - 235px);
  }
  @media screen and (max-width: 600px) {
    min-height: calc(100vh - 157px);
    height: calc(100%);
  }

  &:fullscreen {
    height: 100vh;
  }

  max-width: 400px;

  background: #1c1c1c;
  color: black;
  text-align: center;
  border-radius: 60px 20px 0px 0px;

  -webkit-box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.26);
  box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.26);

  &--globe-icon-click-wrapper {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    text-align: center;
    padding: 5px;
    border-radius: 15px;
    background-color: black;
    height: 30px;
    width: 30px;
    box-shadow: 0px 5px 30px 5px rgba(0, 0, 0, 0.15);

    svg {
      height: 20px;
      width: 20px;
      color: white;
    }
  }
  &--cog-icon-click-wrapper {
    cursor: pointer;
    position: absolute;
    top: 8px;
    left: 5px;
    content: "";
    transition: opacity 250ms linear;
    width: 24px;
    height: 24px;
    z-index: 10;

    &:hover + .shopfloor-column--cog-icon {
      z-index: 1 !important;
      svg {
        animation-play-state: running !important;
      }
    }
  }

  .shopfloor-column--cog-icon {
    position: absolute;
    top: 7px;
    left: 5px;
    z-index: -1;
    transform: scale(1.4);

    svg {
      -webkit-animation: spin 4s linear infinite;
      -moz-animation: spin 4s linear infinite;
      animation: spin 4s linear infinite;
      animation-play-state: paused;
    }
    @-moz-keyframes spin {
      100% {
        -moz-transform: rotate(360deg);
      }
    }
    @-webkit-keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }

  &__chart {
    padding: 40px 20px;
    cursor: pointer;
    width: 100%;
    max-width: 260px;
    margin: 0 auto;
  }
  &__title {
    height: 30px;
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    margin: 20px 25px 30px;
    flex-shrink: 0;
    display: block;
    text-overflow: ellipsis;
    position: relative;

    span {
      display: block;
    }
    &--overflowed {
      &:not(:hover) {
        text-overflow: ellipsis;
      }

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &:hover,
      &:focus {
        span {
          display: inline-block;
          text-overflow: unset;
          animation: scroll 5s ease 0s infinite normal;

          @keyframes scroll {
            0% {
              transform: translateX(0%);
            }
            90% {
              transform: translateX(-100%);
            }
            95% {
              transform: translateX(0%);
            }
            100% {
              transform: translateX(0%);
            }
          }
        }
      }
    }
  }
  &--header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 3rem;
    font-weight: bold;
    flex-shrink: 0;
    .current {
      cursor: pointer;
    }
    &-item-wrapper {
      margin: auto;
      display: flex;
      align-items: flex-end;
      &-item {
        &-text {
          font-size: small;
          color: #424242;
          margin-bottom: -10px;
        }
      }
    }
  }
  &--subheader {
    width: max-content;
    margin: auto;
    font-size: large;
    color: #424242;
    position: relative;
    &--left-icon {
      position: absolute;
      left: -20px;
      top: 0;
    }

    &--right-icon {
      position: absolute;
      right: -30px;
      top: -2px;
      background: #424242;
      border-radius: 50%;
      height: 24px;
      width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        height: 16px;
        width: 16px;
      }
    }
  }
  &--diagram {
    width: 100%;
    box-sizing: border-box;
    padding: 40px 20px;
    flex-shrink: 0;
  }
  &--tasks {
    flex-grow: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding-bottom: 50px;
    &__new-task {
      margin: 0 auto;
      cursor: pointer;
      svg {
        height: 30px;
        width: 30px;
      }
    }
  }

  &__chart-pop-up {
    display: flex;
    flex-direction: column;
    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &__navigate-month {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      min-width: 180px;
      margin-top: 10px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
    &__legend {
      &__child {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        & > div {
          height: 24px;
          width: 24px;
          background-color: #fd9595;
          border-radius: 17px;
          margin-right: 5px;
        }

        padding: 6px;
      }
      &__child:last-child > div {
        background-color: #bdfd95;
      }
    }
  }
  &__general-tab {
    &__max-fail-count__wrapper {
      display: flex;
      .checkbox-component {
        align-self: flex-end;
        height: 40px;
        margin: 10px 0;
        margin-right: 10px;
      }
    }
    &__avg-title {
      font-weight: bold;
    }
  }
  &__visual-tab {
    &__options {
      display: flex;
      flex-direction: row;

      .input-component {
        width: max-content;
      }

      .button-component {
        width: max-content;
      }
      &__percentage-checkbox {
        margin-left: auto;
        display: flex;
        align-items: center;
      }
    }
  }
  &__edit__header {
    display: flex;
    align-items: center;

    .button-component {
      margin-left: auto;
      width: max-content;
    }
    &__button-content {
      display: flex;
      align-items: center;
      p {
        display: block;
        -webkit-transition: 0, 15s linear;
        -moz-transition: 0.15s linear;
        transition: 0.15s linear;
        max-width: 0;
        overflow: hidden;
        white-space: nowrap;
      }
      &:hover {
        p {
          margin-right: 4px;
          max-width: 150px;
        }
      }
    }
  }
  &__unit {
    padding: 10px;
    span {
      font-weight: bold;
    }
    span:empty:before {
      content: "\200b";
    }
  }
}
