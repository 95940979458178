.filter-ml-responses {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 20px);
  flex-wrap: wrap;

  > div {
    display: flex;
    align-items: center;
  }
}

.whole-result-count {
  font-weight: bold;
  font-size: 3em;
}

.gate-result-entry-wrapper {
  margin: 20px 0;
  width: calc(100% - 20px);
  display: flex;
  min-height: 200px;
  position: relative;

  .delete-result-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }

  .status-wrapper {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 75px;
    min-width: 75px;
    color: white;
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    font-size: 1.5em;
    padding-right: 5px;

    &.status-0 {
      background: #138c35;
    }
    &.status-2 {
      background: #f9a701;
    }
    &.status-1 {
      background: #eb0114;
    }
  }

  .result-text {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-right: 35px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    flex-grow: 1;
    background: #f3f3f3;

    .collap-wrapper {
      border: 1px solid #dedede;
      background: white;
      padding: 10px;
      border-radius: 10px;
      margin-top: 10px;

      .collap-trigger {
        cursor: pointer;
        user-select: none;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .chevron-icon {
          svg {
            height: 12px;
          }
        }
      }

      .violations-entry-wrapper {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        border: 1px solid #e6e6e6;

        .violation-data-entry {
          display: flex;
          padding: 6px 0;

          &:nth-child(2n + 1) {
            background: #f2f2f2;
          }

          .data-key {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
            word-break: break-all;
            text-align: right;
          }

          .data-data {
            margin-left: 10px;
            flex-grow: 1;
            word-break: break-all;
            text-align: left;
          }
        }
      }
    }

    .image-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-left: -5px;

      &--entry {
        cursor: pointer;
        text-align: center;
        margin: 5px;
        font-size: 0.8em;

        .icon {
          margin-bottom: -15px;
        }
      }
    }
  }
}
