.ml-page {
  &__wrapper {
    padding-left: 20px;
    max-width: 800px;
    margin: auto;
  }

  &__button-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: -22px;
  }

  &__header {
    padding-left: 10px;
  }
  &__image-wrapper {
    text-align: center;
    img {
      object-fit: contain;
    }
  }
  &__stage-wrapper {
    width: 80%;
    height: 56vh;
    justify-content: center;
    margin: 4% auto;
    .loader-component {
      margin-right: 10%;
    }
  }
  &__stage {
    .konvajs-content,
    canvas {
      margin: auto;
      width: 100%;
      height: 100%;
    }
  }

  &__stage-content {
    display: flex;
    flex-direction: column;
  }

  &__sub-images {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;

    .image-canvas {
      flex-basis: 45%;

      canvas {
        width: 100%;
      }
    }
  }
  &__sub-images-header {
    padding-left: 20px;
  }

  &__text-detection {
    margin: auto;
    width: 80%;
    padding-top: 30px;
    padding-bottom: 10px;
  }

  &__popup-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  &__popup-image {
    width: 80%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;

    img {
      width: 100%;
      object-fit: contain;
    }
  }

  &__popup-text {
    margin-left: 10%;
    width: 80%;
    .text-detection {
      &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
        * {
          font-size: 18px;
        }
        > div {
          display: flex;

          div:nth-child(even) {
            margin-left: auto;
          }
        }
      }
    }
  }
}
