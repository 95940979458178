.notification-page {
  .notification {
    &__mark-all {
      width: max-content;
      margin-left: auto;
      &__button-content {
        display: flex;
        align-items: center;

        p {
          display: block;
          -webkit-transition: 0, 15s linear;
          -moz-transition: 0.15s linear;
          transition: 0.15s linear;
          max-width: 0;
          overflow: hidden;
          white-space: nowrap;
        }
        &:hover {
          p {
            margin-right: 4px;
            max-width: 300px;
          }
        }
      }
    }
    &__card {
      * {
        flex: none;
      }
      &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        h1 {
          padding-left: 10px;
          padding-top: 10px;
          font-size: 1em;
          font-weight: bold;
        }
        svg {
          height: 24px;
          width: 24px;
          align-self: flex-start;
        }
      }
      &__content {
        padding-left: 10px;
        text-align: start;
        font-weight: normal;
      }
      &__timestamp {
        padding-right: 10px;
        text-align: end;
        padding: 0 10px;
        color: #424242;
        font-size: small;
      }
    }
  }
}
