@mixin article-wrapper {
  @media screen and (min-width: 800px) {
    max-width: 50%;
    padding: 6vw 6vh;
    transform-style: preserve-3d;

    * {
      transform-style: preserve-3d;
    }
    @-moz-document url-prefix() {
      * {
        transform-style: unset;
      }
    }
  }
  margin: 0 3rem;
  margin-bottom: 3rem;
  height: max-content;
  .button-component {
    width: max-content;
  }
}
@mixin box-styles {
  position: absolute;
  content: "";
  height: 25rem;
  width: 25rem;
  border-radius: 80px;

  z-index: -1;
  @media screen and (min-width: 800px) {
    height: 100%;
    width: 100%;
    border-radius: 40px;
  }
}

.landing-page {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
  perspective: 8px;
  perspective-origin: 0 50%;

  .button-component button {
    @media screen and (min-width: 800px) {
      padding: 5px 30px;
    }
    padding: 5px 20px;

    height: fit-content;
  }
  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    &__logo {
      @media screen and (max-width: 800px) {
        position: absolute;
        top: 2rem;
        left: 25%;
      }
      @media screen and (min-width: 800px) {
        padding-left: 2rem;
        flex: 1;
      }
      img {
        max-height: 4rem;
      }
    }
    &__button__wrapper {
      @media screen and (max-width: 800px) {
        display: none;
      }
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 0 2rem;

      &--mobile {
        @media screen and (min-width: 800px) {
          display: none;
        }
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .button-component {
          width: fit-content;
        }
      }
    }
  }
  &__main {
    &__wrapper {
      @media screen and (min-width: 800px) {
        max-width: 40%;
        margin-top: 15rem;
        margin-left: 6rem;
      }
      margin: 13rem 3rem 3rem;
    }
  }

  &__content {
    margin: auto;
    max-width: 1300px;
    transform-style: preserve-3d;
  }

  &__articles-top {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 800px) {
      margin-top: 10rem;
      flex-direction: row;
      gap: 4rem;
      transform-style: preserve-3d;
    }
  }

  &__firstArticle {
    @include article-wrapper();
    @media screen and (min-width: 800px) {
      margin-top: 5rem;
      transform: translateX(-1rem) translateZ(1px) scale(0.9);

      &::after {
        @include box-styles();
        background-color: rgb(248, 107, 107);
        top: -1rem;
        left: 0;
        transform: rotate(-7deg);
      }
    }
  }

  &__secondArticle {
    @include article-wrapper();
    @media screen and (min-width: 800px) {
      transform: translateX(-1rem);
      &::after {
        @include box-styles();
        background-color: rgb(169, 250, 162);
        top: 0;
        left: 0;
        transform: rotate(10deg);
      }
      .button-component {
        margin-left: auto;
      }
    }
  }

  &__thirdArticle {
    @include article-wrapper();

    @media screen and (min-width: 800px) {
      transform: translateX(-6rem) translateZ(2px) scale(0.8);
      margin: 0 auto;
      margin-top: -3rem;
      padding-top: 7rem;
      &::after {
        @include box-styles();
        background-color: #fdd875;
        top: 0;
        left: 0;
        transform: rotate(5deg);
      }

      .button-component {
        margin: 0 auto;
      }
    }
  }
  &__fourthArticle {
    @include article-wrapper();

    @media screen and (min-width: 800px) {
      padding-bottom: 5vh;
      transform: translateX(1rem) translateZ(1px) scale(0.95);
      margin: 0 auto;
      margin-top: -3rem;
      padding-top: 4rem;
      margin-bottom: 100px;
      &::after {
        @include box-styles();
        background-color: #67b4e8;
        top: 0;
        left: 0;
        transform: rotate(-5deg);
      }

      .button-component {
        margin: 0 auto;
      }
    }
  }
  &__subscription {
    width: 100%;
    margin-top: 20px;
    &-gdpr {
      width: 96%;
      margin-top: 20px;
      &--description {
        margin-top: 10px;
      }
      &--legal {
        margin-top: 10px;
      }
      &--input {
        padding: 10px;
        border: none;
        &-notification {
          padding-top: 5px;
          cursor: pointer;
        }
        .gdpr-checkbox {
          cursor: pointer;
          margin-right: 10px;
        }
        label {
          cursor: pointer;
        }
      }
      a {
        color: black;
      }
      a:visited {
        color: black;
      }
    }
    .button-component-disabled {
      opacity: 0.6;
    }
    .retractable-component--bordered {
      p {
        padding: 10px;
      }
    }
    .input-component-enabled {
      border: none;
    }
  }

  &__footer {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    gap: 15px;
    font-size: 0.8rem;
    padding: 1rem 4rem;
    flex-wrap: wrap;

    @media screen and (max-width: 800px) {
      background-color: rgb(26, 20, 19);
      border-top-left-radius: 70px;
      border-top-right-radius: 20px;
      padding: 2rem 3rem;

      div > p,
      div > a {
        color: white;
        font-size: 1rem;
        line-height: 1rem;
      }
    }

    &__contact {
      display: flex;
      flex-direction: column;
    }
    p,
    a {
      color: black;
      padding: 0.2rem 0.5rem;
    }
  }

  // visual boxes
  &__box {
    @include box-styles();
    &--1 {
      background-color: rgb(248, 107, 107);
      top: -30%;
      left: -60%;
      transform: rotate(10deg);
    }
    &--2 {
      background-color: rgb(169, 250, 162);
      top: -30%;
      left: -10%;
      transform: rotate(45deg);
    }
    &--3 {
      background-color: #fdd875;
      top: -28%;
      left: 35%;
    }
    &--4 {
      background-color: rgb(140, 144, 244);
      top: -20%;
      left: 75%;
      transform: rotate(-10deg);
    }
    @media screen and (min-width: 800px) {
      &--1 {
        background-color: rgb(248, 107, 107);
        height: 500px;
        width: 500px;
        top: -40rem;
        left: 50%;
        right: unset;
        z-index: -1;
        transform: rotate(60deg) translateZ(-5px);
      }
      &--2 {
        background-color: rgb(26, 20, 19);
        height: 350px;
        width: 350px;
        top: 5rem;
        right: 20%;
        left: unset;
        z-index: -1;
        transform: rotate(10deg) translateZ(3px);
      }
      &--3 {
        background-color: rgb(140, 144, 244);
        height: 500px;
        width: 500px;
        top: 10rem;
        right: -15rem;
        left: unset;
        z-index: -1;
        transform: rotate(-10deg) translateZ(1px);
      }

      &--4 {
        background-color: rgb(26, 20, 19);
        height: 25vw;
        width: 35vw;
        max-width: 500px;
        max-height: 400px;
        bottom: -40%;
        top: unset;
        left: 20%;
        right: unset;
        z-index: -3;
        transform: rotate(10deg) translateZ(1px);
      }
    }
  }
}

.landing-page {
  display: flex;
  align-items: center;
  justify-content: center;
  .clipped-wrapper {
    position: absolute;
    filter: drop-shadow(0px 5px 30px rgba(50, 50, 0, 1));

    .clipped {
      clip-path: polygon(0 0, 80% 0, 20% 100%, 0 100%);
      width: 100vw;
      height: 100vh;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: translate(-20%, 0);
      }
    }
  }

  &__user-action {
    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 12px;
      z-index: 1;
      width: 50%;
      height: 40%;
      max-width: 800px;
      max-height: 600px;
      background-color: white;
      border-radius: 20px;
      box-shadow: 6px 6px 5px rgba(50, 50, 0, 0.6);

      @media screen and (max-width: 625px) {
        width: 100%;
        height: 100%;
        border-radius: 0px;
        max-width: 100%;
        max-height: 100%;
      }
    }

    &__image-container {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;

      img {
        object-fit: contain;
        width: 100%;
      }
    }

    &__button-container {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      gap: 10%;
      flex-wrap: nowrap;

      @media screen and (max-width: 625px) {
        flex-wrap: wrap;
      }

      .button-component {
        max-width: 200px;

        button {
          padding: 10px 30px;
        }
      }
    }
  }
}
