@import "../../../styles/variables.scss";

.protocol-export-view {
  display: none;
}

@media print {
  @page {
    size: A4 portrait;
    margin: 1cm;
  }
  .protocol-export-view {
    display: inherit;
    pointer-events: none;
    width: 100%;
    padding-bottom: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-size: 14px;
    .protocol-header {
      display: flex;
      align-items: center;
    }
    .company-logo {
      top: 0;
      left: 0;
      width: 70px;
      height: 70px;
      object-fit: contain;
    }
    .input-value-pair {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      padding: 10px;
      box-sizing: border-box;
      word-wrap: break-word;
      page-break-inside: avoid;
      &:last-of-type {
        border: none;
      }
      &.summary {
        padding: 10px 0;
      }
      &.HIGH {
        border-left: 3px solid $red;
      }
      &.MEDIUM {
        border-left: 3px solid $blue;
      }
      &.LOW {
        border-left: 3px solid $green;
      }
      label {
        font-weight: bold;
      }
      img {
        border-radius: 15px;
        margin-top: 10px;
        max-width: 100%;
        max-height: 300px;
        object-fit: contain;
      }
    }
  }
}
