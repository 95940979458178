@import "./Group.scss";

.power-bi {
  &--list {
    .box-component-wrapper--box--task {
      display: none;
    }
  }
  @include groupList;
  &__overview {
    width: 100%;
    margin: 0 auto;
    max-width: 1050px;

    &--adding {
      padding: 0px 20px;
      text-align: right;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &-view {
        display: flex;
        flex-direction: row;
        align-items: center;
        .view-svg {
          margin-right: 20px;
          height: 40px;
          width: 40px;
        }
        .list-icon {
          height: 32px;
        }
        .active {
          fill: #407bfd;
        }
      }

      svg {
        height: 50px;
        width: 50px;
        cursor: pointer;
      }
    }

    &--grid {
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
    }
    @include groupList;
  }
  &__embedded-wrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
    iframe {
      border: none;
    }
  }
}
