.energy-edit {
  &__header {
    display: flex;
    align-items: center;
    h2 {
      flex: 1;
    }
    &__button-content {
      display: flex;
      align-items: center;
      p {
        display: block;
        -webkit-transition: 0, 15s linear;
        -moz-transition: 0.15s linear;
        transition: 0.15s linear;
        max-width: 0;
        overflow: hidden;
        white-space: nowrap;
      }
      &:hover {
        p {
          margin-right: 4px;
          max-width: 150px;
        }
      }
    }
    .button-component {
      margin-left: auto;
      width: max-content;
    }
  }
}

.fileName-wrapper {
  display: flex;
  align-items: center;
  p:last-child {
    margin-left: auto;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
}

.download-example {
  text-align: center;
  display: block;
  color: rgb(150, 154, 250);

  &:visited {
    color: rgb(150, 154, 250);
  }
}
