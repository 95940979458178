.administration-page {
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  padding: 0 10px;

  .tab-component {
    margin-bottom: 10px;
    max-width: 100%;
    overflow-x: scroll;
  }
}

.general-admin {
  display: flex;
  flex-direction: column;

  h2 {
    margin-top: 30px;
    margin-bottom: 10px;
  }

  &--logo {
    display: flex;
    flex-direction: row;

    &--buttons {
      display: flex;
      flex-direction: column;

      &--upload {
        display: flex;
        flex-direction: row;

        .button-component {
          width: fit-content;
        }

        &--content {
          display: flex;
          align-items: center;
        }

        &--remove {
          background-color: blue;

          svg {
            color: white;
            height: 24px;
            width: 24px;
          }
        }
      }
    }

    img {
      height: 130px;
      width: 130px;
      background: repeating-conic-gradient(#d4d4d4 0% 25%, transparent 0% 50%)
        50%/50px 50px;
    }
  }
}

.default-admin {
  position: relative;

  &--delete-popup {
    margin: 15px 0;
  }

  &--header {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;

    .button-component {
      width: fit-content;
    }

    &--add {
      color: white;
      display: flex;
      align-items: center;

      svg {
        height: 24px;
        width: 24px;
        fill: white;
      }

      p {
        display: block;
        -webkit-transition: 0, 15s linear;
        -moz-transition: 0.15s linear;
        transition: 0.15s linear;
        max-width: 0;
        overflow: hidden;
        white-space: nowrap;
      }

      &:hover {
        p {
          margin-right: 4px;
          max-width: 150px;
        }
      }
    }
  }

  &--self {
    opacity: 30%;
  }
}

.data-api-group {
  h2 {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}
