.energy-detail {
  &--go-back {
    background-color: #407bfd;
    border-radius: 50%;
    padding: 0.2rem;
  }
  &--wrapper {
    padding-left: 10px;
    width: 100%;

    &--content-wrapper {
      max-width: 1050px;
      margin: 0 auto;

      &--source-wrapper {
        text-align: right;
        opacity: 0.5;
        font-size: 12px;
        padding-right: 16px;
      }
    }

    &_menu {
      max-width: 1050px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      &.kpi-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;

        &.overview-wrapper {
          display: flex;
          flex-direction: column;
          gap: 30px;
          padding-left: 10px;
          .overview-totals {
            display: flex;
            gap: 2%;
            div {
              width: 24%;
            }
          }
        }
      }

      .retractable-component--header {
        padding: 0 10px;

        &--title {
          font-weight: bold;
        }
      }

      &--item {
        display: flex;
        cursor: pointer;

        &.filter-wrapper {
          margin-bottom: 5px;
          border-bottom: 2px solid transparent;

          .active {
            border-bottom: 2px solid #407bfd;
          }

          > div {
            padding: 0 10px;
          }
        }

        svg {
          height: 40px;
          width: 40px;
        }
        .active {
          fill: #407bfd;
        }

        .grap-distance {
          margin-right: 20px;
        }
      }
    }
  }
}
