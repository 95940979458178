.id-field {
  margin-left: 5px;
}

.company-edit {
  &__checkbox {
    &__wrapper {
      display: flex;
      align-items: center;
      height: 60px;

      .checkbox-component {
        flex: 1;
      }
    }
    &__date {
      flex: 2;
      display: flex;
      align-items: center;
      p {
        padding: 0 5px;
      }
      .input-component {
        max-width: 250px;
      }
    }
  }
}
