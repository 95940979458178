.text-widget {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
}

.time-widget {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;

  h2 {
    font-size: 3em;
    line-break: anywhere;
  }

  &--demo {
    margin-left: 15px;
    padding-top: 0px !important;
    margin-bottom: 15px;
  }

  &--table {
    margin-top: 10px;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
}

.count-widget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;

  .header {
    font-size: 1.1em;
  }

  .count {
    font-size: 3em;
    font-weight: bold;
  }
}
