@import "variables";

.protocol-detail {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 0px;
  padding-left: 20px;
  max-width: 1635px;
  width: 80%;
  margin: auto;
  @media screen and (max-width: 600px) {
    padding: 0px;
    width: 100%;
  }
  .popup-component-wrapper--content {
    text-align: center;
  }

  &__document-wrapper {
    margin-left: 12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
    svg {
      height: 20px;
    }
  }
  &__document {
    display: flex;
    gap: 12px;

    a {
      display: flex;
      align-items: center;
      gap: 4px;
      text-decoration: none;
      color: $blue;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__header,
  &__edit-header {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    svg {
      width: 30px;
      margin: 0 12px 0 auto;
      cursor: pointer;
    }
    .edit-icon,
    .download-icon {
      width: 40px;
      height: 40px;
      margin: 0;
    }

    @media screen and (max-width: 600px) {
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 0 6px;
    }
  }
  &__title-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  &__qr-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 50px;
      width: 50px;
    }
    @media screen and (max-width: 600px) {
      margin-top: 0;
      margin-left: auto;
      margin-right: 20px;
    }
  }
  &__slug {
    font-size: 12px;
    font-weight: bold;
  }
  &__fields {
    margin: auto;
    padding: 10px 0px;

    width: 100%;
    max-width: 800px;
  }
  &__no-inputs {
    display: flex;
    justify-content: center;
  }
  &__image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .camera-input-component {
      max-width: 800px;
      min-width: 400px;
      width: 50%;
    }
  }
  &__placeholder {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__input {
    margin-left: 12px;
    &.HIGH {
      border-left: 3px solid $red;
    }
    &.MEDIUM {
      border-left: 3px solid $blue;
    }
    &.LOW,
    &.undefined {
      border-left: 3px solid $green;
    }

    img {
      width: 200px;
      height: 200px;
      object-fit: contain;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__barcode-wrapper {
    display: flex;
    align-items: center;

    .button-component {
      margin-top: 20px;
      max-width: 100px;
      // cursor: pointer;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.protocol-edit {
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__box,
  &__document-config-wrapper {
    flex-grow: 1;
    display: flex;
    gap: 35px;
  }
  &__box {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }
  &__document-config-wrapper {
    flex-basis: calc(50% - 18px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  &__supervisers-wrapper,
  &__inputs-wrapper,
  &__documents-wrapper,
  &__config-wrapper {
    width: 100%;
    flex-basis: calc(50% - 18px);
    flex-grow: 1;
    max-width: 800px;
    @media screen and (min-width: 800px) {
      min-width: 600px;
    }
    @media screen and (max-width: 799px) {
      width: 100%;
    }
    display: flex;
    flex-direction: column;
    gap: 36px;
    background-color: #e6e6f8;
    padding: 2%;
    border-radius: 20px;
    @media screen and (max-width: 600px) {
      border-radius: 0px;
    }
  }
  &__inputs-header {
    margin-top: 20px;
    margin-left: 12px;
  }
  &__document-type {
    display: flex;
    flex: 1;
    svg {
      height: 20px;
    }
    .button-component {
      flex: 1;
      padding-top: 30px;
    }
    .upload-component {
      flex-grow: 1;
      padding-top: 0px;
    }

    .icon-button {
      width: 60px;
    }
  }
  &__document-wrapper {
    display: flex;

    > .input-component:nth-child(1) {
      max-width: 40%;
    }
  }
  &__supervisers-wrapper {
    p,
    .user-tags {
      margin: 0 20px;
      padding: 0;
    }
  }
  &__config-wrapper {
    ul {
      box-sizing: border-box;
      padding-left: 27px;
      li {
        div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          svg {
            cursor: pointer;
          }
        }
      }
    }
  }
  &__input-container {
    padding: 10px 0px;

    @media screen and (max-width: 600px) {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }
  &__button-container {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    width: 100%;
  }
  &__header {
    display: flex;
    align-items: center;
    h2 {
      flex: 1;
    }
    &__button-content {
      display: flex;
      align-items: center;
      p {
        display: block;
        -webkit-transition: 0, 15s linear;
        -moz-transition: 0.15s linear;
        transition: 0.15s linear;
        max-width: 0;
        overflow: hidden;
        white-space: nowrap;
      }
      &:hover {
        p {
          margin-right: 4px;
          max-width: 150px;
        }
      }
    }
    .button-component {
      margin-left: auto;
      width: max-content;
    }
  }
  &__input-flex {
    * {
      white-space: nowrap;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1;
    flex-wrap: wrap;
    row-gap: 0px;

    .checkbox-component {
      margin: auto;
      padding-top: 20px;
      margin-left: 12px;
      @media screen and (max-width: 600px) {
        margin: 0;
        margin-left: 12px;
      }
    }

    &__upper,
    &__lower {
      display: flex;
      width: 100%;
      @media screen and (max-width: 800px) {
        flex-wrap: wrap;
      }
    }
  }
  &__input-flex-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  &__dropdown-values {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    @media screen and (max-width: 700px) {
      max-width: 96vw;
      width: 96vw;
    }
  }
  &__controls-container {
    display: flex;
    margin-top: 20px;
    margin-left: 20px;
    gap: 20px;
    align-items: center;

    @media screen and (max-width: 600px) {
      gap: 8px;
      margin-top: unset;
      margin: auto;
      padding-top: 20px;
    }
  }
  &__up-down-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__summary-wrapper {
    margin-left: 8px;
  }
}
.user-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  > div {
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: #969afa;
    padding: 5px;
    border-radius: 5px;
    svg {
      height: 20px;
      cursor: pointer;
      border-left: 1px solid black;
      padding-left: 5px;
    }
  }
}
