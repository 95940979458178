.energy-prediction-page {
  &--wrapper {
    padding: 10px;
  }

  &--header {
    padding: 16px;
    h3 {
      padding-left: 12px;
    }
  }

  &--wrapper-menu {
    display: flex;
    align-items: center;
  }
}
