.group-edit-form {
  &--footer {
    display: flex;
  }

  .table-component__wrapper {
    table {
      svg {
        height: 25px;
        width: 25px;
        vertical-align: middle;
      }
    }
  }
}
