.profile {
  &__wrapper {
    margin: 0 50px;
    &-groups {
      margin-top: 20px;
      padding: 10px;
      &-group {
        width: 100%;
        text-align: center;
        border: 2px solid rgb(150, 154, 250);
        background-color: rgb(150, 154, 250);
        color: white;
        font-weight: bold;
        height: 40px;
        border-radius: 30px 10px;
        margin-top: 10px;
        padding: 10px;
        cursor: default;
      }
    }
    padding-bottom: 80px;
  }
}
