.notification-rotation-component {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .section-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
    flex-basis: 400px;
  }

  .input-wrapper-inline {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > div:first-child {
      text-align: start;
      width: 100px;
      @media screen and (max-width: 800px) {
        width: 100%;
      }
    }
    .dropdown-component {
      width: 100%;
      max-width: 400px;
    }
    .number-text-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 400px;
      justify-content: flex-start;
      .input-component {
        max-width: 80px;
      }
    }

    .week-day-wrapper {
      display: flex;
      flex-wrap: wrap;
      padding: 0 10px;
      height: 60px;
      align-items: center;
      box-sizing: border-box;

      &--entry {
        border: 2px solid rgb(150, 154, 250);
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
        cursor: pointer;
        border-radius: 5px;
        user-select: none;

        &.active {
          background: rgb(150, 154, 250);
          color: white;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .rule-listing {
    border-top: 1px solid black;
    padding-top: 10px;
    position: relative;
    .remove-rule {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
