.dashboard {
  flex-grow: 1;

  &--wrapper {
    display: flex;
    flex-direction: row;
  }

  &--grid {
    max-width: 1050px;
    margin: 0 auto;

    .react-grid-layout {
      min-height: 50vh;
      > div {
        border-radius: 45px 15px 45px 45px;
        padding: 10px;

        .react-resizable-handle {
          transform: translateY(-5px) translateX(-5px);
        }
      }
    }
  }

  &--sidebar-placeholder {
    @media only screen and (max-width: 800px) {
      display: none;
    }
    width: 200px;
  }

  &--sidebar {
    &--wrapper {
      position: fixed;
      top: 100px;
      right: 0;
      bottom: 0;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media only screen and (max-width: 800px) {
      display: none;
    }
    background-color: #969afa;
    border-radius: 20px 0 0 20px;
    overflow-y: scroll;

    &--buttons {
      padding: 10px 0;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .button-component {
        padding: 0px 10px;
      }
    }
  }

  &--header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 40px;
    max-width: 1050px;

    &--edit {
      @media only screen and (max-width: 800px) {
        display: none;
      }
      cursor: pointer;
      width: 32px;
      height: 32px;
    }

    &--buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .button-component {
        padding: 0;
        width: 125px;
      }
    }
  }

  &--body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &--widget {
    height: 100%;
    overflow: hidden;

    .config-button {
      position: absolute;
      top: -8px;
      left: -8px;
      cursor: pointer;
      z-index: -1;

      svg {
        width: 35px;
        height: 35px;

        -webkit-animation: spin 4s linear infinite;
        -moz-animation: spin 4s linear infinite;
        animation: spin 4s linear infinite;
        animation-play-state: paused;
      }
      @-moz-keyframes spin {
        100% {
          -moz-transform: rotate(360deg);
        }
      }
      @-webkit-keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
      @keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      &:hover {
        z-index: 1;
        svg {
          animation-play-state: running;
        }
      }
    }

    .delete-button {
      position: absolute;
      right: 5px;
      top: 2px;
      cursor: pointer;

      svg {
        height: 25px;
        width: 25px;
      }
    }
  }

  &--widget-config {
    p {
      padding-top: 10px;
    }
  }

  &--widget-store {
    width: 200px;

    .react-grid-layout {
      > div {
        background-color: #f86b6b;
        border-radius: 20px;
        padding: 10px;
      }
    }
  }
}
