.register {
  &__page {
    height: 100vh;
    overflow: hidden;
    &__title {
      padding: 10px;
      &__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
          height: 48px;
          width: 48px;
        }
      }
    }
  }
  &__form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__wrapper {
    flex: 1;
    display: flex;
    overflow: hidden;
    background-color: #fdd875;
  }
  &__side-menu {
    flex: 1;
    display: flex;
    flex-direction: column;
    &__card {
      flex: 1;
      margin: 0 15px 50px;
      display: flex;
      flex-direction: column;
      background-color: rgb(150, 154, 250);
      border-radius: 0 0 50px 50px;
      padding: 10px 20px;

      .button-component button {
        color: white;
      }
    }
  }
  &__navigation {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__item {
      margin: 10px 10px;
      cursor: pointer;
      font-weight: bold;
      transition: 0.1s;
      color: black;
      opacity: 0.5;
      &--focused {
        font-weight: bold;
        opacity: 1;
      }
    }
  }
  &__info-box {
    flex: 2;
    overflow: scroll;
  }
  &__form-tab {
    min-height: 100vh;
    padding: 10% 25px;

    & > div {
      background-color: white;
      height: 100%;
      padding: 50px 30px;
      border-radius: 50px 20px 50px 50px;
      & > p {
        font-weight: bold;
        font-size: 40px;
        line-height: 49px;
        margin-bottom: 20px;
      }
    }
  }
}
